/* eslint-disable react/jsx-props-no-spreading */
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React from "react"
import { v4 as uuid } from "uuid"
import { Section, Container } from "../../UI/Common"



const SectionHeadingContent = loadable(() =>
  import("./SubComponents/SectionHeadingContent")
)
const SubHeadingContent = loadable(() =>
  import("./SubComponents/SubHeadingContent")
)
const TextBlockContent = loadable(() =>
  import("./SubComponents/TextBlockContent")
)
const ImageContent = loadable(() => import("./SubComponents/ImageContent"))

const TwoImgBlockContent = loadable(() =>
  import("./SubComponents/TwoImgBlockContent")
)
const VideoContent = loadable(() => import("./SubComponents/VideoContent"))

const ButtonContent = loadable(() => import("./SubComponents/ButtonContent"))

const ActionButton = loadable(() => import("./SubComponents/ActionButton"))



const SimpleContent = ({ fieldGroupName, content, config }) => {
  const { backgroundColour, sectionPadding, alignment } = config || {}
  const alignmentBase = "d-flex flex-column align-items"

  const styles = {
    start: `${alignmentBase}-start`,
    center: `${alignmentBase}-center text-center`,
    end: `${alignmentBase}-end text-end`,
  }

  return (
    <Section padding={sectionPadding} className={`${fieldGroupName} ${backgroundColour}`}>
      <Container className={styles[alignment]}>
        <>
        {content?.map(block => {
          const componentName = block.fieldGroupName.replace(
            /(Post_Postslayouts_PostObject_PostBody|Page_Flexlayouts_FlexibleLayouts_SimpleContent)_Content_/,
            ""
          )
          switch (componentName) {
            case "SectionHeading":
              return <SectionHeadingContent key={uuid()} {...block} />
            case "SubHeading":
              return <SubHeadingContent key={uuid()} {...block} />
            case "TextBlock":
              return <TextBlockContent key={uuid()} {...block} />
            case "Image":
              return <ImageContent key={uuid()} {...block} />
            case "TwoImgBlock":
              return <TwoImgBlockContent key={uuid()} {...block} />
            case "Video":
              return <VideoContent key={uuid()} {...block} />
            case "Button":
              return <ButtonContent key={uuid()} {...block} />
            case "ActionButtons":
              return  <ActionButton  key={uuid()} {...block} />
            default:
              return ""
          }
        })}
        </>
      </Container>
    </Section>
  )
}

SimpleContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.array,
}

SimpleContent.defaultProps = {
  content: null,
}

export default SimpleContent
