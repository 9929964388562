import { graphql } from "gatsby"
import React from "react"

import SimpleContent from "./SimpleContent"

export const fragment = graphql`
  fragment SimpleContentFragment on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent {
    fieldGroupName
    content {
      ... on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent_Content_SectionHeading {
        fieldGroupName
        heading
        config {
          alignment
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent_Content_SubHeading {
        fieldGroupName
        heading
        config {
          alignment
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent_Content_TextBlock {
        fieldGroupName
        text
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent_Content_Button {
        fieldGroupName
        button {
          title
          url
          target
        }        
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent_Content_Image {
        fieldGroupName
        image {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 856
                height: 482
                placeholder: TRACED_SVG
              )
            }
          }
        }
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent_Content_TwoImgBlock {
        fieldGroupName
        twoImgBlock {
          leftImg {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 700
                  height: 394
                  placeholder: TRACED_SVG
                )
              }
            }
          }
          rightImg {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 700
                  height: 394
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPage_Flexlayouts_FlexibleLayouts_SimpleContent_Content_Video {
        fieldGroupName
        videoTitle
        videoUrl
        videoPreviewImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 700
                placeholder: TRACED_SVG
              )
            }
          }
        }
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
    },
    config {
      backgroundColour
      contained
      alignment
      sectionPadding: padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFSimpleContent = ({ fieldGroupName, content, config }) => (
  <SimpleContent fieldGroupName={fieldGroupName} content={content} config={config} />
)
